.loaderBlueFullView {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.full {
    width: 100px;
    height: 100px;
}
